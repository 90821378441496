footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 2.5vh 0 2.5vh 0;
	color: var(--off-white);
	background-color: var(--off-blue);
}

footer p {
	text-align: center;
	font-size: 0.8rem;
	width: 70%;
}

footer a {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}
