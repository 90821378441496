.p-grid-item {
	cursor: zoom-in;
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}

.p-grid-img {
	position: relative;
	width: 100%;
	filter: none;
	transform: scale(100%);
	transition: opacity var(--slow-transition), transform var(--medium-transition), filter var(--medium-transition);
	margin: 0;
}

.p-grid-item:hover .p-grid-img {
	filter: grayscale(100%) brightness(40%) blur(15px);
	transform: scale(110%);
}

.p-grid-item strong {
	pointer-events: none;
	position: absolute;
	width: 80%;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	font-family: Outfit, sans-serif;
	color: var(--off-white);
	font-size: 2rem;
	filter: opacity(0);
	transition: filter var(--medium-transition);
}

.p-grid-item:hover strong {
	filter: opacity(1);
}

@media (max-aspect-ratio: 16/11) {
	.p-grid-item {
		width: 100%;
	}

	.p-grid-item strong {
		word-wrap: normal;
	}

	.p-grid-img {
		width: 100%;
	}
}
