@font-face {
	font-family: "Arvo";
	src: local("Arvo"), url(./assets/fonts/Arvo/Arvo-Regular.ttf) format('truetype');
}

@font-face {
	font-family: "Outfit";
	src: local("Outfit"), url(./assets/fonts/Outfit/Outfit-VariableFont_wght.ttf) format('truetype');
}

:root {
	--off-white: #EEE;
	--off-gray: #E2E2E2;
	--off-orange: #e7b388;
	--off-blue: #0d3959;
	--error-border: #A4031F;
	--error-background: #FEC3CD;
	--success-border: #679436;
	--success-background: #D7E9C3;
	--info-background: #F8E9DD;
	--fast-transition: 167ms;
	--medium-transition: 250ms;
	--slow-transition: 500ms;
	font-size: 1rem;
}

#root {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--off-white);
	color: var(--off-blue)
}

html {
	scroll-behavior: smooth;
}

section {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.section-blue {
	background-color: var(--off-blue);
	color: var(--off-white);
}

.nodisplay {
	display: none;
}

.yesdisplay {
	display: block;
}

.error-comp {
	padding-top: 20vh;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

main {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	animation-name: mainfadein;
	animation-duration: var(--slow-transition);
}

@keyframes mainfadein {
	0% {
		opacity: 0;
	}

	33% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

h1 {
	font-family: Outfit, sans-serif;
	font-size: 3rem;
	font-weight: 800;
}

h2 {
	font-family: Outfit, sans-serif;
	font-size: 2rem;
	font-weight: 500;
}

h3 {
	font-family: Outfit, sans-serif;
}

P {
	font-family: Arvo, serif;
}

a {
	font-family: Outfit, sans-serif;
}

button {
	font-family: Outfit, sans-serif;
}

label {
	font-family: Arvo, serif;
}

ul {
	font-family: Arvo, serif;
}

strong {
	font-family: Outfit, sans-serif;
}

@media (max-aspect-ratio: 8/9) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.6rem;
	}
}
