.bb {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
}

.bb:visited {
	color: inherit;
}

.bb svg {
	margin-right: 1rem;
	font-size: 2rem;
}

.bb p {
	display: flex;
	align-items: center;
	color: var(--off-blue)
}

@media (max-aspect-ratio: 8/9) {
	.bb {
		flex-direction: column;
	}

	.bb svg {
		margin-right: 0.5rem;
	}
}
