.contact-form-section {
	position: relative;
	padding: 0;
	min-height: 60vh;
	overflow-x: hidden;
}

.contact-form {
	position: relative;
	width: 90vw;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--off-blue);
}

#twoTarget {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-form-active {
	animation-name: fade-in;
	animation-duration: 0.354s;
	animation-timing-function: cubic-bezier(0.4, 0.8, 0.9, 0.95);
}

.contact-form-inactive {
	animation-name: fade-out;
	animation-duration: 0.354s;
	animation-timing-function: cubic-bezier(0.4, 0.8, 0.9, 0.95);
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateX(10vw);
	}

	50% {
		opacity: 0;
		transform: translateX(10vw);
	}

	100% {
		opacity: 1;
		transform: translateX(0vw);
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
		transform: translateX(0vw);
	}

	50% {
		opacity: 0;
		transform: translateX(-10vw);
	}

	100% {
		opacity: 0;
		transform: translateX(-10vw);
	}
}

.contact-form fieldset {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form-label {
	position: relative;
	width: 80%;
	display: grid;
	grid-template-areas: "text input"
		"void hint";
	grid-template-columns: 1fr 7fr;
	grid-template-rows: auto auto;
	align-items: center;
	font-size: 1.2rem;
}

.contact-input {
	grid-area: input;
	width: calc(100% - 8px);
	height: 2rem;
	font-family: Arvo, serif;
	font-size: 1rem;
}

.contact-input:disabled {
	background-color: gray;
}

.input-hint {
	grid-area: hint;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: calc(80% - 2rem);
	height: 0;
	padding: 0 1rem 0 1rems;
	overflow: hidden;
	color: var(--off-blue);
	background-color: var(--error-background);
	border: 1px solid var(--error-border);
	border-radius: 10px;
	text-align: center;
	transition: filter var(--fast-transition), height var(--medium-transition);
	filter: opacity(0);
	z-index: 10;
}

.contact-input:focus {
	border-color: var(--off-blue);
}

.contact-input:user-invalid {
	border-color: red;
}

.contact-input:user-invalid+.input-hint {
	height: auto;
	filter: opacity(1)
}

.details-label {
	margin: 2rem 0 2rem 0;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.2rem;
	font-weight: 500;
}

.details-input {
	margin-top: 1rem;
	display: block;
	width: calc(100% - 8px);
	max-width: 80vw;
	height: auto;
	font-size: 1rem;
}

.details-input:focus {
	border-color: var(--off-blue);
}

.details-input:disabled {
	background-color: gray;
}

[type=submit] {
	height: 4rem;
	width: 25%;
	margin: 5vh 0 5vh 0;
	border-radius: 10px;
	font-size: 1.2rem;
	border: 2px solid var(--off-blue);
	cursor: pointer;
	transition: color var(--fast-transition), background-color var(--fast-transition);
}

[type=submit]:hover {
	color: var(--off-white);
	background-color: var(--off-blue);
}

.review-output {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10vh;
	min-height: 30vh;
	width: 50vw;
	max-width: 1200px;
	position: relative;
	text-overflow: ellipsis;
}

.review-output ul {
	width: 100%;
	max-width: 800px;
	overflow-wrap: break-word;
	list-style: none;
	padding: 0;
	text-overflow: inherit;
}

.review-output button {
	height: 4rem;
	width: 25%;
	margin: 5vh 0 5vh 0;
	border-radius: 10px;
	font-size: 1.2rem;
	border: 2px solid var(--off-blue);
	cursor: pointer;
	transition: color var(--fast-transition), background-color var(--fast-transition);
}

.review-output button:hover {
	color: var(--off-white);
	background-color: var(--off-blue);
}

.response-div {
	width: calc(100% - 10px);
	max-width: calc(800px - 10px);
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	border: 5px solid var(--off-blue);
	background-color: rgba(0, 0, 0, 0);
	font-size: 1rem;
	margin: 1rem;
}

.response-div svg {
	display: block;
	font-size: 4rem;
	margin: 1rem;
	transform-origin: center;
	width: auto;
}

.response-div p {
	margin: 1rem;
}

.fetch-success {
	border: 5px solid var(--success-border);
	background-color: var(--success-background);
	color: var(--off-blue);
}

.fetch-success svg {
	fill: var(--success-border)
}

.fetch-fail {
	border: 5px solid var(--error-border);
	background-color: var(--error-background);
	color: var(--off-blue);
}

.fetch-fail svg {
	fill: var(--error-border)
}

.icon-resolved svg {
	animation: none;
}

.icon-pending svg {
	animation: spinning 1s infinite;
	animation-timing-function: ease-in-out;
}

@keyframes spinning {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}

.re-disclaimer {
	margin: 1rem 0 0 0;
	display: block;
	text-align: center;
	width: 70%
}

.nodisplay {
	display: none;
}


@media (max-aspect-ratio: 4/3) {
	.contact-form {
		width: 100%;
	}

	.review-output {
		width: 100%;
	}
}

@media (max-aspect-ratio: 8/9) {
	.contact-form {
		width: 88vw
	}

	.contact-input {
		font-size: 1rem;
	}

	.form-field {
		width: 70%;
		grid-template-areas: "label"
			"input"
			"hint";
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto;
		justify-items: center;
	}

	.form-field-two {
		width: 70%;
	}

	.form-label {
		font-size: 1rem;
		column-gap: 10px;
	}

	.details-label {
		font-size: 1rem;
	}

	[type=submit] {
		width: calc(70% - 4px);
	}

	.review-output {
		width: 70%;
		padding: 15%;
	}

	.re-disclaimer {
		width: 70%;
	}

	.review-output button {
		width: 50%;
	}

	.response-div svg {
		font-size: 8rem;
	}


}
