.load-container {
	min-width: 100px;
	min-height: 100px;
}

.img-loading {
	visibility: hidden;
	opacity: 0;
}

.img-loaded {
	visibility: visible;
	opacity: 1;
}
