.portfolio-grid {
	display: grid;
	margin-top: 2rem;
	grid-template-columns: repeat(5, 18%);
	column-gap: 1rem;
	row-gap: 1rem;
	justify-content: center;
	width: 80%;
	max-width: 1600px;
	filter: none;
}

.p-grid-blur {
	filter: blur(25px);
}

.paginator {
	display: block;
	width: 20vw;
	height: 4rem;
	margin: 5vh;
	border: 2px solid var(--off-blue);
	border-radius: 10px;
	cursor: pointer;
	font-size: 1.2rem;
	transition: color var(--fast-transition), background-color var(--fast-transition);
}

.paginator:hover {
	color: var(--off-white);
	background-color: var(--off-blue);
}

@media (max-aspect-ratio: 16/11) {
	.portfolio-grid {
		grid-template-columns: repeat(3, 30%);
	}
}

@media (max-aspect-ratio: 8/9) {
	.portfolio-grid {
		width: 70%;
		grid-template-columns: 100%;
	}

	.paginator {
		width: 70%;
	}
}
