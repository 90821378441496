.scroll-header {
	height: 20vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 2;
	top: 0;
	background-color: var(--off-white);
	animation-name: headerfadein;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	transition: top var(--fast-transition), height var(--medium-transition);
	transition-timing-function: ease-in-out;
}

.shrunken {
	height: 8vh;
}

.h-header {
	position: sticky;
}

.p-header {
	position: fixed;
	height: 8vh;
}

.scrolled-down {
	top: -10vh;
}

@keyframes headerfadein {
	0% {
		opacity: 0;
	}

	40% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}

}

@media (max-aspect-ratio: 8/9) {
	@keyframes headerfadein {
		0% {
			opacity: 0;
		}

		40% {
			opacity: 0;
			transform: translateX(20vw);
		}

		80% {
			opacity: 1;
		}

		100% {
			transform: translateX(0);
		}
	}

}

nav {
	width: 80%;
	max-width: 1600px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 1.2rem;
}

.mobile-nav {
	display: flex;
}

nav ul {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

nav img {
	position: relative;
	height: 4rem;
	width: auto;
}

.nav-link {
	text-decoration: none;
	padding: 1rem;
	border-radius: 5px;
	color: var(--off-blue);
	background-color: var(--off-white);
	transition: color var(--fast-transition), background-color var(--fast-transition);
}

.portfolio-navbar-link {
	background-color: var(--off-gray);
}

.nl-inactive {
	color: var(--off-blue);
	background-color: var(--off-white);
}

.nav-link:hover {
	background-color: var(--off-blue);
	color: var(--off-white);
}

.nav-list-item {
	list-style: none;
	margin: 0 0.5rem 0 0.5rem;
}

.nav-bb {
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.nav-bb img {
	height: 4rem;
	width: auto;
}

.nl-active {
	color: var(--off-white);
	background-color: var(--off-blue);
}

.mob-toggle {
	display: none;
}

.mob-returner {
	display: none;
	background-color: red;
}

@media (max-aspect-ratio : 8/9) {
	.scroll-header {
		flex-direction: row-reverse;
		right: 0;
		width: 33%;
		justify-content: flex-start;
		align-items: baseline;
		background-color: rgba(0, 0, 0, 0);
	}

	.h-header {
		position: fixed;
	}

	nav {
		position: fixed;
		flex-direction: column;
		justify-content: center;
		z-index: 10;
		height: 100vh;
		background-color: var(--off-white);
		right: -66%;
		width: 33%;
		transition: right 250ms;
		font-size: 1rem;
	}

	.mobile-nav {
		right: 0;
	}

	nav ul {
		position: relative;
		flex-direction: column;
		justify-content: flex-start;
		margin: 0;
		padding: 0;
		width: 100%;
		margin-top: 2rem;
		border-top: 2px solid rgba(220, 220, 220, 1)
	}

	.nav-list-item {
		margin: 0;
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.nav-link {
		display: block;
		width: 100%;
		padding: 0;
		text-align: center;
		border-radius: 0;
		padding: 2rem 0 2rem 0;
		border-bottom: 2px solid rgba(220, 220, 220, 1);
		transition: border-bottom var(--fast-transition);
	}

	.nav-link:hover {
		border-bottom: 2px solid var(--off-blue);
	}

	.nl-active {
		border-bottom: 2px solid var(--off-blue);
	}

	.mob-toggle {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: fixed;
		font-size: 2rem;
		margin: 2vw;
		z-index: 9;
		color: var(--off-blue);
		background-color: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 50%;
		padding: 2vw;
		transition: color var(--fast-transition), background-color var(--fast-transition), opacity var(--fast-transition);
	}

	.mobile-nav+.mob-toggle {
		opacity: 0;
	}

	.mob-toggle:hover {
		color: var(--off-white);
		background-color: var(--off-blue);
	}

	.mob-returner {
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
		height: 100vh;
		width: 100vw;
		z-index: 8;
	}

	.mb-active {
		display: block;
	}

	.mb-inactive {
		display: none;
	}

	.nav-bb {
		flex-direction: column;
		width: 90%;
	}

	.nav-bb svg {
		margin: 0 0.5rem 0 0;
	}

	.scrolled-down {
		top: 0;
	}
}
