#services {
	background-color: var(--off-gray);
	min-height: 80vh;
	padding: 10vh 0 10vh 0;
}

.services-grid {
	width: 100%;
	max-height: 60vh;
	margin-top: 2rem;
	display: grid;
	grid-template-columns: repeat(4, 18%);
	column-gap: 2%;
	justify-content: center;
	align-items: center;
}

.s-link {
	height: 50vh;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	overflow: hidden;
}

.s-link img {
	position: relative;
	height: 150%;
	width: auto;
	filter: none;
	transform: scale(100%);
	transition: filter var(--medium-transition), transform 2s;
}

.s-link:hover img {
	filter: grayscale(100%) brightness(33%);
	transform: scale(110%);
}

.s-link-text {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	transform: translateY(-50%);
	width: 100%;
	height: 20%;
	top: 50%;
	font-size: 2rem;
	color: var(--off-white);
	opacity: 0;
	text-align: center;
	transition: opacity var(--fast-transition);
}

.s-link-text strong {
	font-size: 2.4rem;
	font-weight: 400;
}

.s-link-text p {
	margin: 0;
	font-size: 1.6rem;
	font-weight: 100;
}

.s-link:hover .s-link-text {
	opacity: 1;
}

.portfolio-link {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 50vh;
	height: calc(100% - 8px);
	align-items: center;
	text-decoration: none;
	color: var(--off-blue);
	border: 4px solid var(--off-blue);
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0);
	font-size: 2rem;
	transition: color var(--fast-transition), background-color var(--fast-transition);
}

.portfolio-link:hover {
	background-color: var(--off-blue);
	color: var(--off-white);
}

.portfolio-link strong {
	display: block;
	width: 80%;
	text-align: center;
	font-size: 2.4rem;
	font-weight: 400;
}

@media (max-aspect-ratio: 16/11) {
	.services-grid {
		grid-template-columns: repeat(2, 35%);
		row-gap: 2%;
		max-height: none;
	}

	.s-link img {
		width: 125%;
		height: auto;
	}

}

@media (max-aspect-ratio: 8/9) {
	.services-grid {
		width: 80%;
		display: flex;
		flex-direction: column;
		margin: 0;
	}

	.s-link {
		width: 80%;
		height: 40vh;
		margin: 1rem 0 1rem 0;
	}

	.s-link-text {
		width: 80%;
		max-width: 300px;
		top: 75%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		opacity: 1;
		font-size: 1.4rem;
		color: var(--off-white);
		border-radius: 10px;
		background-color: var(--off-blue);
		box-shadow: 10px 10px black;
	}

	.s-link-text strong {
		font-size: 1.8rem;
		font-weight: 600;
	}

	.s-link-text p {
		font-size: 1rem;
		font-weight: 800;
	}

	.portfolio-link {
		width: 80%;
		height: 40vh;
		margin: 1rem 0 1rem 0;
	}

	.portfolio-link strong {
		font-size: 1.8rem;
		font-weight: 600;
	}
}
