.review-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 80%;
	animation-name: reviewfadein;
	animation-duration: 500ms;
}

.review-header {
	min-width: 15%;
	text-align: right;
}

.review-content {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	padding: 1rem 0 1rem 2rem;
	margin: 0 0 0 2rem;
	border-top: none;
	border-left: 5px solid var(--off-blue);
}

.star-grid {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.star-grid svg {
	display: block;
	margin: 0 4px 0 4px;
	fill: var(--off-orange);
	font-size: 1.4rem;
}

@media screen and (max-aspect-ratio: 16/11) {
	.review-item {
		flex-direction: column;
		margin: 0;
		width: 70%;
	}

	.review-header {
		text-align: center;
		width: 100%;
	}

	.review-content {
		border-top: 5px solid var(--off-blue);
		border-left: none;
		padding: 1rem 0 1rem 0;
		margin: 0;
	}

	.star-grid {
		justify-content: center;
	}
}

@keyframes reviewfadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
