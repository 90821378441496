.browse-prompt {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.browse-prompt h1 {
	margin: 1rem;
}

.browse-prompt svg {
	font-size: 1.6rem;
	margin-right: 1em;
}

.browse-list {
	list-style: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0;
}

.browse-list li {
	margin: 1rem;
}

.browse-link {
	text-decoration: none;
	padding: 1rem;
	border-radius: 5px;
	color: var(--off-blue);
	background-color: var(--off-white);
	transition: color var(--fast-transition), background-color var(--fast-transition);
	font-size: 1.2rem;
}

.browse-link:hover {
	color: var(--off-white);
	background-color: var(--off-blue);
}

.port-error {
	min-height: 100vh;
}

.port-error p {
	font-size: 1.2rem;
}

@media (max-aspect-ratio: 8/9) {
	.browse-list {
		flex-direction: column;
	}
}
