.portfolio-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	padding: 20vh 0 20vh 0;
}

.portfolio-main h1 {
	display: block;
	text-align: center;
}
