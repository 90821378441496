.p-view {
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 3;
	top: 0;
	animation-name: p-view-fadein;
	animation-duration: var(--medium-transition);
}

@keyframes p-view-fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.outer-returner {
	position: fixed;
	height: 100vw;
	width: 100vw;
	bottom: 0;
	z-index: 4;
	cursor: zoom-out;
}

.p-view-figure {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	height: 100vh;
	min-width: 300px;
	margin: 0;
}

.p-view-figcap {
	position: absolute;
	bottom: 8vh;
	display: block;
	z-index: 5;
	height: calc(18vh - 2rem);
	padding: 1rem 0 1rem 0;
	width: calc(90% - 1rem);
	max-width: 500px;
	border-radius: 10px;
	background-color: var(--off-white);
	filter: drop-shadow(0 0 10px black);
}

.p-view-figcap div {
	height: 100%;
	overflow-y: auto;
}

.p-view-figcap p {
	margin: 1rem;
}

.p-view-figcap strong {
	margin: 1rem;
}

.p-view-img {
	position: relative;
	max-width: 70vw;
	height: auto;
	max-height: 90vh;
	z-index: 5;
	animation-name: figimgmove;
	animation-duration: 272ms;
}

.inner-returner {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	top: 8vh;
	right: 3vh;
	padding: 0.5rem;
	border-radius: 10px;
	color: var(--off-white);
	font-size: 2rem;
	background-color: var(--off-blue);
	border: 2px solid var(--off-blue);
	z-index: 5;
	cursor: pointer;
	transition: fill var(--fast-transition), background-color var(--fast-transition);
}

.inner-returner:hover {
	color: var(--off-blue);
	background-color: var(--off-white);
}

@keyframes figimgmove {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.item-ind {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 10px;
	z-index: 5;
	font-size: 4rem;
	background-color: rgba(0, 0, 0, 0.0);
	color: var(--off-white);
	transition: background-color var(--fast-transition), color var(--fast-transition);
	margin: 2rem;
}

.item-ind:hover {
	color: var(--off-blue);
	background-color: var(--off-white);
}

.item-ind:disabled {
	opacity: 0;
}

@media (max-aspect-ratio: 8/9) {
	.item-ind {
		position: relative;
		z-index: 12;
		background-color: var(--off-blue);
		top: 20vh;
		margin: 1rem;
	}

	.p-view-figure {
		position: relative;
		flex-direction: column;
		justify-content: flex-end;
		height: 100vh;
		min-width: 50px;
	}

	.p-view-img {
		max-height: 60vh;
		max-width: 70vw;
		margin-bottom: 10vh;
	}

	.p-view-figcap {
		position: relative;
		opacity: 1;
		margin-bottom: 5vw;
		bottom: 0;
		min-height: 18vh;
		width: 90vw;
	}


	.p-view-figcap p {
		margin: 0 1rem 1rem 1rem;
	}

	.inner-returner {
		font-size: 1.4rem;
		top: 7vw;
		right: 2vw;
	}
}

.image-ind {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 10px;
	z-index: 5;
	font-size: 3rem;
	background-color: rgba(66, 66, 66, 0.6);
	color: var(--off-white);
	transition: background-color var(--fast-transition), color var(--fast-transition);
	margin: 2rem;
}

.image-ind:hover {
	color: var(--off-blue);
	background-color: var(--off-white);
}

.image-ind:disabled {
	opacity: 0;
}

.ind-dec {
	position: absolute;
	left: 2%;
	z-index: 10;
}

.ind-inc {
	position: absolute;
	right: 2%;
	z-index: 10;
}

@media (max-aspect-ratio: 8/9) {
	.image-ind {
		background-color: var(--off-blue);
		font-size: 2.4rem;
		bottom: 25%;
	}
}
