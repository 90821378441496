#reviews {
	padding: 10vh 0 10vh 0;
}

.reviews ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	list-style: none;
	padding: 0;
}

.reviews-list-item {
	margin: 1rem;
}

.reviews-list-item a {
	fill: var(--off-blue);
	display: block;
	border-radius: 10px;
	transition: background-color var(--fast-transition);
}

.reviews-list-item svg {
	display: block;
	fill: inherit;
	padding: 1rem;
	font-size: 8rem;
	border-radius: 10px;
	background-color: inherit;
	transition: fill var(--fast-transition), background-color var(--fast-transition);
	max-height: 8rem;
}

.reviews-list-item a:hover {
	fill: var(--off-white);
	background-color: var(--off-blue);
}

.cslb-grid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.cslb-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	border-radius: 10px;
	margin: 1rem;
	background-color: rgba(0, 0, 0, 0);
	filter: none;
	transition: background-color var(--fast-transition);
}

.cslb-link img {
	filter: none;
	transition: filter var(--fast-transition);
}

.cslb-link:hover {
	background-color: var(--off-blue);
}

.cslb-link:hover img {
	filter: grayscale(100%) brightness(400%);
}

@media (max-aspect-ratio: 8/9) {
	.reviews ul {
		padding: 0;
		margin: 0 1rem 0 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 0;
		row-gap: 0;
	}

	.reviews-list-item a {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.reviews-list-item svg {
		font-size: 6rem;
	}

	.cslb-grid {
		display: flex;
		flex-direction: column;
		width: 90%;
		margin-top: 4rem;
	}

	.cslb-link {
		margin: 0;
		width: 60%;
	}

	.cslb-link img {
		width: 100%;
		max-width: 300px;
	}
}
