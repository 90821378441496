.returnToTop {
	cursor: pointer;
	position: fixed;
	bottom: 10vh;
	left: 5vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 3px solid var(--off-blue);
	border-radius: 10px;
	color: var(--off-blue);
	background-color: var(--off-gray);
	opacity: 1;
	transition: color var(--fast-transition), background-color var(--fast-transition), opacity var(--fast-transition)
}

.returnToTop svg {
	font-size: 2rem;
	display: block;
	margin: 0.4rem;
}

.returnToTop p {
	display: none;
	font-size: 1rem;
	margin: 0;
	margin-right: 0.4rem
}

.returnToTop:hover {
	color: var(--off-white);
	background-color: var(--off-blue);
}

.returnToTop:hover p {
	display: block;
}

.returnToTop-hidden {
	display: none;
}
