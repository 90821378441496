.spinframe {
	position: absolute;
	transform: translateX(-50%) translateY(-50%);
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-around;
}

.spinframe svg {
	font-size: 4rem;
	animation-name: spinner;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
