#home {
	height: 72vh;
	padding: 10vh 0 0 0;
}

#home p {
	display: block;
	max-width: 50%;
	margin: 4rem 4rem 0 4rem;
	font-size: 1.4rem;
	animation-name: heroblurbfadein;
	animation-duration: 1s;
}

.hero-logo {
	max-height: 50vh;
	max-width: 50%;
}

@keyframes heroblurbfadein {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-aspect-ratio: 8/9) {
	#home {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		height: 100vh;
	}

	#home p {
		font-size: 1rem;
		margin: 4rem 0 0 0;
		max-width: 70%;
	}

	.hero-logo {
		max-width: 70%;
	}
}
