#contact {
	background-color: var(--off-gray);
	padding: 10vh 0 10vh 0;
}

.info-grid {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin-top: 2rem;
	width: 70%;
}

.info-grid h2 {
	margin: 0;
}

.static-info {
	padding: 1rem;
	width: auto;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-radius: 10px;
	color: var(--off-blue);
}

.static-info svg {
	display: block;
	grid-area: svg;
	font-size: 2.4rem;
	margin: 1rem;
}

.static-info p, .static-info a {
	color: inherit;
	text-decoration: none;
	grid-area: info;
	font-size: 1.4rem;
	margin: 0.5rem 0 0.5rem 0;
}

.static-info div {
	grid-area: info;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (max-aspect-ratio: 8/9) {
	.static-info {
		font-size: 1rem;
		margin: 1rem 0;
		padding: 0;
	}

	.static-info svg {
		font-size: 1rem;
		margin: 1rem;
	}

	.static-info p {
		font-size: 1rem;
	}
}
