.review-carousel {
	width: 80%;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 2rem;
}

.review-carousel button {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	border: none;
	font-size: 4rem;
	color: var(--off-blue);
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 166ms, color 166ms;
	padding: 0;
}

.review-carousel button:hover {
	background-color: var(--off-blue);
	color: var(--off-white);
}

.review-carousel button:disabled {
	visibility: hidden;
}

@media (max-aspect-ratio: 8/9) {
	.review-carousel {
		width: 100%;
		margin: 0;
		align-items: baseline;
		justify-content: space-between ;
	}

	.review-carousel button {
		font-size: 2rem;
	}
}
